@use 'theme' as *;

.section-title {
  color: var(--onSecondary);
  font-family: var(--default);
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
  margin: 0 0 36px 0;
  letter-spacing: -1.12px;
  text-align: left;

  &-plus {
    margin-bottom: 40px;
  }
}
