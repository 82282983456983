.banner {
  background-color: #000000;
  background-image: url(https://cdnweb.devolutions.net/web/common/images/background/devolutions-blog-header.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: #ffffff;
  height: 225px;
  padding-left: 0;
  padding-right: 0;
  transition: padding 400ms ease;
}

//css-4x6pjb
.banner-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  .banner-left {
    align-self: center;
    flex: 1;
  }

  .banner-right {
    align-self: center;
    flex: 1;
    text-align: right;
    padding-right: 100px;

    @media (max-width: 767px) {
      display: none;
    }

    .banner-tagline {
      color: #ffffff;
      display: inline-block;
      font-size: 24px;
      font-weight: 700;
      line-height: 1;
      padding-left: 8px;
      text-align: left;

      p {
        margin: 0 0 0 0;
      }
      @media (max-width: 1023px) {
        display: none; 
      }
    }
  }

  .logo-in-the-trenches {
    display: block;
    height: 130px;
    width: auto;
    margin-left: -12px;
    margin-top: -10px;

    @media (max-width: 767px) {
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }

  .logo-the-devolutions-blog {
    display: block;
    height: 15px;

    @media (max-width: 767px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
