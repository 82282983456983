/* Poll */

.poll {
  display: grid;
  grid-template-columns: 4fr 1fr 2fr;
  grid-gap: 40px; 
  padding: 20px 60px;
  width: 100%;
  height: fit-content;
  background-color: #F7F7F7;
  margin-bottom: 50px; 
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;

  
  @media (max-width: 1023px) {
    grid-template-columns: 4fr 1fr;
    grid-gap: 20px; 
    img {
      grid-row: span 2;
      align-self: center;
    }
    > div {
      display: grid;
      .answer-here {
        grid-row-start: 1;
      }
    }
    
  }
  @media (max-width: 599px) {
    grid-template-columns: 1fr;
    text-align: center;
    padding: 20px; 
    img {
      display: none;
    }
  }
  img {
    max-width: 158px;
  }
  .container-left {
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    .this-month {
      color: #999999;
      font-weight: 700; 
      padding-bottom: 17px;  
      text-transform: uppercase; 
    }

    .poll-title {
      font-size: 20px;
      font-weight: 700;
      max-width: 532px;  
    }
  }
  .container-right {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    gap: 10px; 
    align-items: center;
    @media (min-width: 1024px) {
      text-align: center;
    } 
    @media (max-width: 1023px) {
      flex-direction: column-reverse;
      align-items: flex-start; 
    }
    @media (max-width: 599px) {
      align-items: center; 
    }
    .answer-here {
      text-transform: uppercase; 
      background-color: #FFCC66;
      font-weight: 700; 
      padding: 4px; 
      font-size: 16px; 
      max-width: 210px;
      width: 100%;
      max-height: 34px;
      height: 100%;  
      display: flex;
      justify-content: center;
      align-items: center; 
      margin-bottom: 15px;
      color: #000;
      &:focus, &:visited {
        color: #000; 
      }

    }
    

  }

}
