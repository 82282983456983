/* Read-more */

.read-more {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  font-size: 28px;
  line-height: 30px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1.4px;
  opacity: 1;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  a {
    color: var(--onSecondary);
  }
}
