@use 'theme' as *;
@use 'variables' as *;

.global-footer {
  color: var(--onPrimary);
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 50px;
  padding-top: 90px;
  text-align: center;

  &.bg-footer {
    background-image: url(https://cdnweb.devolutions.net/web/common/images/background/devolutions-blog-footer.jpg);
    background-size: auto 250%;
    background-position: center 85%; //-79px -369px
    background-repeat: no-repeat; 
    width: 100%;
    @media (min-width: 1500px) {
      background-size: cover; 
    }

   
  }

  * {
    margin-bottom: 0;
  }

  > * > * + * {
    margin-top: 12px;
  }

  p {
    text-align: center;
    opacity: 0.8;
    line-height: 1;
  }

  &-devo-logo {
    display: inline-flex;
    justify-content: center;

    img {
      height: 40px;
      fill: currentColor;
    }
  }

  &-lang {
    display: flex;
    justify-content: center;

    a {
      color: var(--onPrimary);
      line-height: 1.4;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      opacity: 0.6;

      + a {
        margin-left: 25px;
        color: var(--onPrimary);
        opacity: 0.7;

        &:before {
          background-color: var(--onPrimary);
          content: '';
          height: 100%;
          pointer-events: none;
          position: absolute;
          left: -13px;
          width: 1px;
        }
      }

      &:hover {
        color: var(--onPrimary);
        opacity: 1;
      }

      &.active {
        color: var(--onPrimary);
        opacity: 1;
      }
    }
  }

  &-social-media {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 30px 0;

    .logo {
      display: flex;
      padding: 8px;
      margin: 6px;
      color: var(--onPrimary);

      @each $socialMedia, $color in $socialMedias {
        &.logo-#{$socialMedia} {
          &:hover {
            color: $color;
          }
        }
      }

      svg {
        height: 32px;
        width: 32px;
      }
    }
  }

  &-help {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
  }

  &-contact {
    display: flex;
    justify-content: center;

    a {
      color: var(--onPrimary);
      line-height: 1.4;
      position: relative;
      text-decoration: none;
      opacity: 0.8;

      + a {
        margin-left: 25px;
        color: var(--onPrimary);
        opacity: 0.8;

        &:before {
          background-color: var(--onPrimary);
          content: '';
          height: 100%;
          pointer-events: none;
          position: absolute;
          left: -13px;
          width: 1px;
        }
      }

      &:hover {
        color: var(--onPrimary);
        opacity: 1;
      }

      &.active {
        color: var(--onPrimary);
        opacity: 1;
      }
    }
  }
}
