@use 'variables' as *;
@use 'theme' as *;
@use 'fonts' as *;

.follow-us-block {
  display: flex;
  flex-direction: column;

  .follow-us-logos {
    background-color: var(--followUsBackground);
    display: flex;
    flex-direction: column;
    padding: 24px 22px 18px;
    width: 100%;

    .follow-us-title-logos {
      color: var(--onSecondary);
      font-size: 30px;
      font-weight: bold;
      line-height: 1;

      svg {
        height: 20px;
        margin-right: 8px;
        width: 20px;
      }
    }

    .follow-us-social-media {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      margin: 12px 0 0;

      .logo {
        color: var(--onSurface);
        display: flex;

        @each $socialMedia, $color in $socialMedias {
          &.logo-#{$socialMedia} {
            &:hover {
              color: $color;
            }
          }
        }

        svg {
          height: 48px;
          width: 48px;
        }
      }
    }

    @media (max-width: 640px) {
      padding: {
        left: 24px;
        right: 24px;
      }
    }
  }

  .follow-us-submit {
    background-color: var(--followUsBackground);
    padding: 24px 22px;
    margin-top: 2px;
    margin-bottom: 2px;
    position: relative;
    width: 100%;
    top: 1px;

    @media (max-width: 640px) {
      padding: {
        left: 24px;
        right: 24px;
      }
    }

    &:before,
    &:after {
      content: '';
      height: 12px;
      position: absolute;
      bottom: 15px;
      left: 10px;
      width: calc(50% - 10px);
      z-index: -1;
      -webkit-box-shadow: 0 15px 10px rgba(136, 136, 136, 0.4);
      -moz-box-shadow: 0 15px 10px rgba(136, 136, 136, 0.4);
      box-shadow: 0 15px 10px rgba(136, 136, 136, 0.4);
      -webkit-transform: rotate(-1.7deg);
      -ms-transform: rotate(-1.7deg);
      transform: rotate(-1.7deg);
    }

    &:after {
      -webkit-transform: rotate(1.7deg);
      -ms-transform: rotate(1.7deg);
      transform: rotate(1.7deg);
      right: 10px;
      left: auto;
    }

    .follow-us-title {
      display: flex;
      align-items: center;
      color: var(--onSecondary);
      font-size: 20px;
      font-weight: 600;
      line-height: 1;
      padding: 0 0 12px;

      svg {
        height: 20px;
        margin-right: 8px;
        width: 20px;
      }
    }

    form {
      display: block;

      @media (max-width: 640px) {
        flex-direction: column;
      }
    }

    input {
      color: var(--onSecondary);
      background: var(--onPrimary);
      border: 1px solid var(--onPrimary);
      padding: 10px 0 10px 10px;
      flex: 1;
      font-family: var(--default);
      font-size: 16px;
      line-height: 30px;
      letter-spacing: -0.8px;
      text-align: left;
      width: 100%;
      max-width: 100%;
      outline: 0;
      -moz-transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      -o-transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      -webkit-transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);

      &:focus {
        border-color: var(--onSecondary);
      }
    }

    button {
      background-color: var(--onSecondary);
      border: 1px solid var(--onSecondary);
      color: var(--onPrimary);
      text-align: center;
      letter-spacing: -1px;
      font-family: var(--default);
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      padding: 8px 12px;
      width: 100%;
      text-transform: uppercase;
      -moz-transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      -o-transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      -webkit-transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);

      @media (max-width: 640px) {
        margin: {
          left: 0;
          top: 8px;
        }
      }

      &:hover {
        background-color: var(--onSecondary);
        border-color: var(--onSecondary);
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }
    }

    p {
      color: var(--onSecondary);
      font-size: 12px;
      line-height: 1;
      margin: 8px 0 0;
    }
  }
}
