@use 'variables' as *;

:root,
body {
  //fonts
  --default: proxima-nova, sans-serif;
  --header: proxima-nova, sans-serif;

  //
  --primary: #0068c3;
  --primary-text: #ffffff;
  --primary-hover: #00539c;
  --primaryVariant: #3700b3;
  --secondary: #03dac6;
  --secondaryVariant: #018786;
  --background: #ffffff;
  --surface: #ffffff;
  --pollHeader: #999999;
  --pollBody: #262626;
  --error: #b00020;
  --borderSurface: #e5e5e5;
  --onPrimary: #ffffff;
  --onSecondary: #000000;
  --followUsBackground: #f5f5f5;
  --placeholder: #9e9e9e;
  --tablesBackground: #e0e0e0;
  --tabTitlesBackground: #ebebeb;
  --datetime: #bdbdbd;
  --title: #000000;
  --primary-text: #ffffff;
  --surface-2: #eeeeee;
  --gray-menu-bg: #5F6C79;
  --gray-menu-bg-darker: #4F5C68;

  //navbar-link-styles css -> header.scss
  --navbarBackground: #000000;
  --navbarForeground: #ffffff;

  //
  --onBackground: #424242;
  --onSurface: #000000;
  --onError: #ffffff;
  --spiceworks: #ff7f32;
  --reddit: #ff4500;
  --youtube: #ff0000;
  --twitter: #1da1f2;
  --linkedin: #0a66c2;
  --facebook: #4267b2;
  --instagram: #262626;
  --rss: #f26522;
  --bluesky: #0085ff;

  //categories (new mockup colors)
  --all: #ffffff;
  --customerStories: #ff9933;
  --news: #3399ff;
  --tipsTricks: #99cc33;
  --poll: #ffcc66;
  --sysadminotaur: #ff6633;
  --funlifestyle: #cc33ff;
  --products: #33cc33;
  --review: #339999;
  --team: #0368c3;
  --whitepapers: #993399;
  --security: #ff0033;
  --adventures-of-a-cto: #33CCCC;

  //specs
  --gap: '32px';
  --grid: 4px;

  //media queries
  --lg: '@media (max-width: 1279px)';
  --md: '@media (max-width: 767px)';
  --sm: '@media (max-width: 480px)';
}

[data-theme='server'] {
  --primary: #ff1131;
  --primary-hover: #cc0e27;
  --primary-rgb: 255, 17, 49;
  --primary-darker: #dd001e;
}

[data-theme='password'] {
  --primary: #ff7717;
  --primary-hover: #cc5f12;
  --primary-rgb: 225, 119, 23;
  --primary-darker: #e35e00;
}

[data-theme='gateway'] {
  --primary: #5f03a2;
  --primary-hover: #2b1d81;
  --primary-rgb: 95, 3, 162;
  --primary-darker: #3e148d;
}

[data-theme='free'] {
  --primary: #3AA3FF;
  --primary-hover: #3AA3FF;
}

[data-theme='pam'] {
  --primary: #58A600;
  --primary-hover: #427D00;
  --primary-rgb: 104, 195, 0;
  --primary-rgb-hover: 0, 153, 0;
}

.blog-grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 90px;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  .blog-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 30px;
    margin: 30px 0;

    .article {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .news {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 30px;
    margin: 0 0 30px 0;
    margin-bottom: 60px;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    img {
      height: auto;
      max-width: 100%;
    }

    > *:first-child {
      flex: 0 0 70%;
      max-width: 70%;

      @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    > *:last-child {
      flex: 1;
      max-width: 30%;

      @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .top-news {
      border-right: 1px solid rgba(0, 0, 0, 0.08);
      padding-right: 30px;

      @media (max-width: 767px) {
        border-right: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        margin-bottom: 30px;
        padding-right: 0;
        padding-bottom: 30px;
      }

      .news-article {
        color: var(--team);
        align-items: center;
        display: flex;
        line-height: 1;
        margin-bottom: 0;
        text-transform: capitalize;

        .news-category {
          @each $category, $color in $categories {
            &.category-#{$category} {
              font-family: var(--default);
              font-size: 1.1em;
              line-height: 1.5em;
              font-weight: 600;
              color: white;
              padding: 0 12px;
              margin-right: 30px;
              letter-spacing: -0.56px;
              text-transform: uppercase;
              background: $color;
            }
          }
        }

        .article-author,
        .article-author a,
        .article-datetime {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          text-decoration: none;
          color: var(--team);
          text-transform: none;

          &:hover {
            text-decoration: underline;
          }

          + * {
            margin-left: 10px; 
          }
        }

        .article-datetime {
          color: var(--datetime);

          &:hover {
            text-decoration: none;
          }
        }
      }

      .news-article-title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -1px;
        text-align: left;
        margin: 8px 0;

        a {
          color: var(--onSurface);
        }

        a:hover {
          color: var(--primary);
        }
      }

      .view-all {
        font-family: var(--default);
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        font-style: normal;
        color: var(--team);
        text-align: left;
        letter-spacing: -0.9px;
        text-transform: uppercase;
      }
    }

    .hot-news {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 30px;

      @media (max-width: 767px) {
        padding-left: 0;
      }

      .news-block {
        display: flex;
        flex-direction: column;

        @media (max-width: 767px) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);
          margin-bottom: 30px;
          padding-bottom: 30px;
        }

        .article-body {
          > *:last-child {
            flex: 1;

            @media (max-width: 767px) {
              margin-left: 0;
              margin-top: 0;
              padding-left: 0;
            }
          }
        }

        .article-title {
          font-size: 28px;
          font-weight: 600;
          font-style: normal;
          line-height: 30px;
          letter-spacing: -1.4px;
          margin: 16px 0 12px 0;
          text-align: left;

          a {
            color: var(--onSurface);
            cursor: pointer;
          }

          a:hover {
            color: var(--primary);
          }
        }

        .article-author-date {
          align-items: center;
          display: flex;
          line-height: 1;

          & + * {
            margin-top: 12px;
          }

          .text-body {
            display: block;
          }

          .article-author,
          .article-author a,
          .article-datetime {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 12px;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }

            + * {
              margin-left: 10px; 
            }
          }

          .article-author {
            color: var(--team);
          }

          .article-datetime {
            color: var(--datetime);

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
