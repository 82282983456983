.footer {
  background-image: url(https://cdnweb.devolutions.net/web/common/images/background/devolutions-blog-footer.jpg);
  background-position: center 85%;
  background-size: auto 250%;
  background-repeat: no-repeat;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  font-family: "Red Hat Display", sans-serif;
  font-size: 10px;
  justify-content: center;
  letter-spacing: .32px;
  line-height: 14px;
  padding-bottom: 50px;
  padding-top: 50px;
  text-align: center;

  @media (min-width: 1500px) {
    background-size: cover;
  }

  &__links {
    a {
      color: var(--onPrimary);
      padding-left: 10px;
      padding-right: 10px;

      + a {
        border-left: 1px solid rgba(255, 255, 255, 0.6);
      }
    }
  }

  &__lang {
    display: flex;
    justify-content: center;
    margin-top: 12px;

    a {
      color: rgba(255, 255, 255, 0.6);
      padding-left: 12px;
      padding-right: 12px;
      text-transform: uppercase;

      &:hover,
      &.active {
        color: rgba(255, 255, 255, 1);
      }

      + a {
        border-left: 1px solid rgba(255, 255, 255, 0.6);
      }
    }
  }

  &__logo {
    display: inline-flex;
    img {
      height: 40px;
    }
  }

  &__social-media {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 30px;

    a {
      color: var(--onPrimary);
      display: flex;
      padding: 8px;
    }
  }

  &__tagline {
    display: flex;
    font-size: 12px;
    justify-content: center;
    letter-spacing: -0.36px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}
