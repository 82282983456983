@use '../mixins/transition' as *;
@use '../variables' as *;
@use '../theme' as *;

.dwd-header-container {
  background-color: var(--title);
  display: flex;
  height: 72px;
  @include transition(height 0.4s ease);
  transition: margin-top 0.3s;

  @media (max-width: 1279px) {
    height: 52px;
  }

  + .dwd-header-container {
    transition: margin-top 0.3s;
    position: fixed;
    margin-top: 0px;
    z-index: 999;
    .dwd-header {
      border-bottom: 1px solid #c1c1c1;
      background-color: var(--background);
      color: var(--title);
      @media (max-width: 1279px) {
        display: none;
      }
      .dwd-header-left > * {
        padding: 0;
        img {
          display: none;
          height: 48px;
          width: auto;
        }
        svg {
          display: none;
        }
      }
      .dwd-header-item {
        font-size: 14px;
        text-transform: none;
        text-align: center;

        &.logo {
          svg {
            color: var(--title);
          }
        }
      }
      .dwd-header-right {
        .dwd-button {
          font-weight: bold;
          height: auto;
          margin: auto;
          min-height: 0;
          padding: 8px 20px;
          font-size: 12px;
          @media (max-width: 1279px) {
            display: none;
          }
          span {
            display: flex;
            @media (max-width: 1279px) {
              display: none;
            }
          }

          svg {
            display: none;
            @media (min-width: 1023px) and (max-width: 1279px) {
              display: flex;
            }
          }
        }
        .download {
          background-color: var(--primary);
        }
        .buy {
          background-color: #78b41b;
        }
      }
    }

    &.transparent {
      .dwd-header {
        background-color: rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(4px);
      }
    }

    &.white {
      margin-top: 0px;
      .dwd-header {
        .dwd-header-left img,
        .dwd-header-left svg {
          @media (min-width: 1023px) {
            display: flex;
          }
        }
        .dwd-header-item {
          color: var(--title);
          &:hover {
            color: var(--primary);
          }
        }
      }

      &.top {
        margin-top: -72px;
      }
    }
  }
}

.dwd-header {
  align-items: center;
  background-color: var(--title);
  color: var(--primary-text);
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 40px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  @include transition-property(padding, height);
  @include transition-duration(0.4s);
  @include transition-timing-function(ease);

  @media (max-width: 1279px) {
    height: 52px;
    padding: 0 20px;
  }

  .dwd-header-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
    width: 100%;
  }

  .dwd-header-left {
    align-items: center;
    display: flex;

    .dvl-icon-logo-devolutions-text {
      color: var(--primary-text);
      height: 36px;
      margin-left: -12px;
      width: 100px;
    }
  }

  .dvl-icon-chat {
    &,
    & + span {
      color: #ff6600;
    }
  }

  .dvl-icon-cellphone {
    &,
    & + span {
      color: #78b41b;
    }
  }

  .dwd-header-center {
    display: flex;
    height: 100%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    > span {
      align-items: center;
      color: var(--title);
      display: none;
      font-weight: 700;
      height: 60px;
      letter-spacing: -0.05em;
      padding: 10px 40px;
      text-transform: uppercase;

      @media (max-width: 1023px) {
        display: flex;
        padding: 10px 25px;
      }
    }

    .dwd-subheader-mobile {
      display: none;
      @media (max-width: 1279px) {
        display: flex;
        flex-direction: column;
        :first-child {
          border-top: none;
          img {
            height: 60px;
            margin: auto;
          }
        }
      }
    }

    @media (min-width: 1024px) and (max-width: 1279px) {
      transform: unset;
      position: relative;
      left: unset;
    }

    @media (max-width: 1023px) {
      background-color: var(--background);
      flex-direction: column;
      height: calc(100% - 52px);
      left: -400px;
      padding: 50px 30px;
      position: fixed;
      top: 52px;
      transform: translateX(0);
      transition: transform 0.5s ease;
      width: 400px;
      z-index: 1000;

      .dwd-subheader-mobile + span {
        margin-top: 12px;
      }

      &.active {
        transform: translateX(100%);

        + .dwd-header-backdrop {
          display: block;
        }
      }

      .dwd-header-submenu-container {
        > .dwd-header-item {
          svg {
            margin-left: auto;
            margin-right: -7px;
            transform: rotate(-90deg);
          }
        }
      }

      .dwd-header-item {
        border-top: 1px solid var(--surface-2);
        color: var(--title);
        font-size: 16px;
        height: auto;
        letter-spacing: -0.05em;
        line-height: 40px;
        padding: 10px 25px;
        text-transform: inherit;
        width: 100%;

        &:hover,
        &.active {
          color: var(--title);

          &:after {
            content: none;
          }
        }
      }

      .dwd-header-submenu {
        box-shadow: none;
        display: flex;
        height: 100%;
        left: -400px;
        padding: 50px 30px;
        position: fixed;
        top: 0;
        transition: left 0.5s ease;
        width: 400px;
        z-index: 1000;

        &.active {
          left: 0;
        }
      }
    }

    @media (max-width: 599px) {
      left: -320px;
      max-width: 320px;
      padding-left: 0;
      padding-right: 0;
      width: 100%;

      .dwd-header-submenu {
        max-width: 320px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .dwd-header-submenu-title-container {
      margin-bottom: 20px;
      .dwd-header-submenu-title {
        font-family: $font-red-hat;
        font-weight: bold;
        letter-spacing: 0.32px;
        margin-bottom: 20px;
        margin-left: 32px;
        text-transform: uppercase;
      }
    }

    .dwd-header-submenu-big-item {
      align-content: flex-start;
      border-radius: 10px;
      border: 1px solid var(--surface-2);
      display: flex;
      padding: 24px 32px;

      &.big-size {
        flex-wrap: wrap;
        max-width: 342px;
        min-width: 342px;
        width: 100%;

        @media (max-width: 599px) {
          max-width: 292px !important;
          min-width: 292px !important;
        }

        .d-flex {
          align-items: center;
        }

        &.right-side {
          min-width: 190px;
          max-width: 240px;
          margin: -197px -7px 0 0px;
          max-height: 374px;
          min-height: 374px;

          &.de, &.fr {
            min-width: 190px;
            max-width: 280px;
            margin: -198px -7px 0 0px;
            max-height: 376px;
            min-height: 376px;
            @media (max-width: 1279px) {
              min-width: unset;
              max-width: unset;
              margin: 0 0;
              max-height: unset;
              min-height: unset;
            }
            @media (max-width: 1023px) {
              margin: 0 0;
              min-width: 342px;
            }
            @media (min-width: 1024px) and (max-width: 1279px) {
              flex: 0 0 100%;
              width: 100%;
            }
          }

          @media (max-width: 1279px) {
            min-width: unset;
            max-width: unset;
              margin: 0 0;
            max-height: unset;
            min-height: unset;
          }
          @media (max-width: 1023px) {
              margin: 0 0;
            min-width: 342px;
          }
          @media (min-width: 1024px) and (max-width: 1279px) {
            flex: 0 0 100%;
            width: 100%;
          }
        }

        .dwd-header-submenu-section-header-title {
          color: var(--primary);
          font-family: $font-red-hat;
          font-size: 20px;
          letter-spacing: -0.02em;
          line-height: 18px;
          max-width: 200px;
          margin-left: 20px;
          width: 80%;
          word-break: initial;
        }

        .text-black {
          color: var(--title);
        }

        svg {
          height: 50px;
          min-width: 50px;
          width: 50px;
          color: var(--title);
        }

        &.gray-bg {
          background-color: var(--gray-menu-bg);
                  
          .dwd-header-submenu-section-header-title, .dwd-header-submenu-description {
            color: var(--primary-text);   
          }
      
          &:hover {
            background-color: var(--gray-menu-bg-darker);
          }

        }

      }
      .dwd-header-submenu-description {
        color: var(--title);
        font-family: $font-red-hat;
        font-size: 11px;
        margin-top: 16px;
      }
    }
    .dwd-header-submenu-section-solutions {
      @media (min-width: 1024px) and (max-width: 1279px) {
        justify-content: space-between;
      }
      .solutions-image {
        height: 60px;
        width: 60px;
      }

      .big-size {
        @media (min-width: 1024px) and (max-width: 1279px) {
          flex: 0 0 calc(50% - 10px);
          max-width: unset;
        }
        .de{
          svg{
            @media(max-width:599px){
              height: 30px;
              min-width: 30px;
              width: 30px;
            }
          }
        }
      }
    }
    .dwd-header-submenu-section-solutions,
    .dwd-header-submenu-section-products {
      align-items: stretch;
      gap: 20px;
      @media (max-width: 599px) {
        justify-content: center;
      }
    }
    .dwd-header-submenu-section-products {
      >.right-side {
        display: none;
      }
    }
  }

  .dwd-header-right {
    display: flex;
    height: 100%;

    .dwd-header-submenu {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 10px;
      right: 0;
      top: 72px;
      width: 400px;

      @media (max-width: 1279px) {
        top: 52px;
      }

      @media (max-width: 1023px) {
        right: 20px;
      }

      @media(min-width: 600px){
        position: absolute;
      }

      @media (max-width: 599px) {
        position: fixed;
        right: 16px;
        top: 52px;
        width: calc(100% - 32px);
      }

      > span {
        background-color: var(--title);
        color: var(--primary-text);
        display: block;
        height: 40px;
        letter-spacing: normal;
        line-height: 40px;
        margin: 10px -10px;
        padding: 0 10px;
        text-align: center;
        text-transform: initial;
        width: calc(100% + 20px);
      }

      .dwd-header-submenu-item {
        align-items: center;
        border: none;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        font-size: 13px;
        letter-spacing: normal;
        line-height: 15px;
        height: auto;
        margin: 5px;
        padding: 10px;
        text-align: center;
        text-decoration: none;
        text-transform: initial;
        width: calc(33% - 10px);
        white-space: inherit;

        img {
          height: 58px;
          margin-bottom: 5px;
          margin-right: 0;
          transition: transform 0.3s ease-out;
          width: 58px;
        }

        + .dwd-header-submenu-item {
          border: none;
        }
      }
    }

    .dwd-header-submenu-container {
      > .dwd-header-item {
        svg {
          height: 24px;
          width: 24px;
        }
      }
    }

    .dwd-header-item {
      font-size: 12px;
      padding: 0 6px;

      span {
        @media (max-width: 1500px) {
          display: none;
        }
      }
    }
  }

  .dwd-header-item {
    align-items: center;
    background-color: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    display: flex;
    font-family: $font-red-hat;
    font-size: 16px;
    font-weight: 400;
    height: 100%;
    padding: 0 12px;
    text-transform: uppercase;

    @media (max-width: 1279px) {
      font-size: 14px;
    }

    &:hover,
    &.active {
      color: var(--primary-text);
    }

    &.active {
      &:after {
        background: var(--title);
        border-radius: 4px;
        content: '';
        height: 30px;
        left: 50%;
        position: absolute;
        top: 52px;
        transform: translate(-50%) rotate(135deg) skew(14deg, 14deg);
        width: 30px;
        z-index: 1001;

        @media (max-width: 1279px) {
          height: 22px;
          top: 39px;
          width: 22px;
        }
      }
    }

    &.mobile-menu {
      display: none;
      margin-right: 6px;
      padding: 0 10px;

      &:after {
        content: none;
      }

      @media (max-width: 1023px) {
        display: flex;
      }
    }

    svg + span {
      padding-left: 2px;

      @media (max-width: 1500px) {
        display: none;
      }
    }

    .notification {
      background-color: #ff7717;
      border-radius: 50%;
      display: inline-flex;
      font-size: 14px;
      justify-content: center;
      height: 18px;
      line-height: 18px;
      margin-left: 4px;
      margin-top: -2px;
      width: 18px;
    }
  }

  .dwd-header-submenu-container {
    height: 100%;
    position: relative;

    @media (max-width: 1279px) {
      height: auto;
    }

    > .dwd-header-item {
      svg {
        height: 18px;
        width: 18px;

        @media (max-width: 1279px) {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  .dwd-header-submenu {
    background-color: var(--primary-text);
    box-shadow: 0 12px 50px 0 rgb(0 0 0 / 35%);
    color: var(--title);
    display: none;
    flex-direction: column;
    padding: 40px 48px 50px;
    position: fixed;
    top: 100%;
    width: 100%;
    z-index: 1000;

    &.dwd-header-submenu-company {
      max-height: 509px;
      &.fr{
        min-width: 1200px !important;
        @media(min-width: 1024px) and (max-width: 1279px){
          min-width: 900px !important;
        }
        @media(max-width: 1023px) {
          min-width: 0 !important;
        }
      }
      &.de{
        min-width: 1250px !important;
        @media(min-width: 1024px) and (max-width: 1279px){
          min-width: 900px !important;
        }
        @media(max-width: 1023px) {
          min-width: 0 !important;
        }
      }
      @media (max-width: 1279px) {
        max-height: unset;
        overflow: scroll;
      }
      @media (min-width: 1024px) and (max-width: 1279px) {
        flex-direction: row !important;
      }

      .company-section-menu-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @media (min-width: 1024px) and (max-width: 1279px) {
          margin-left: 24px;
          flex-direction: row;
          justify-content: space-between;
        }

        .dwd-header-submenu-section-company-menu-container {
          @media (min-width: 1024px) and (max-width: 1279px) {
            width: calc(50% - 10px);
          }
        }
      }
      .dwd-header-submenu-title {
        font-family: $font-red-hat;
        font-weight: bold;
        letter-spacing: -0.32px;
        margin-bottom: 20px;
        margin-left: 12px;
        text-transform: uppercase;
      }
      .dwd-header-submenu-section-company-menu-container {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        min-width: 247px;

        @media (max-width: 1279px) {
          margin-left: 0;
          margin-bottom: 50px;
        }

        @media (max-width: 599px) {
          padding: 0 16px;
        }

        &:nth-child(even) {
          margin-top: 44px;

          @media (max-width: 1279px) {
            margin-top: 0;
          }
        }

        .dwd-header-submenu-section-contact-buttons {
          background-color: var(--background);
          border: 1px solid var(--surface-2);
          border-radius: 10px;
          margin-bottom: 16px;
          padding: 9px 24px;

          @media (max-width: 599px) {
            margin-left: 16px;
            margin-right: 16px;
          }

          &:hover {
            cursor: pointer;
          }

          div {
            margin-left: 16px;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: -0.98px;
          }
          img {
            height: 48px;
            width: 48px;
          }

          &.devolutions {
            &:hover {
              border: 1px solid #eaf5ff99;
              background-color: #eaf5ff99;
            }
            div {
              color: var(--primary);
            }
          }
          &.green {
            &:hover {
              border: 1px solid #eaf7e299;
              background-color: #eaf7e299;
            }
            div {
              color: #78b41b;
            }
          }
          &.orange {
            &:hover {
              border: 1px solid #fff5eb99;
              background-color: #fff5eb99;
            }
            div {
              color: #ff6600;
            }
          }
        }
        .dwd-header-submenu-section-company-buttons {
          border-top: 1px solid var(--surface-2);
          font-size: 16px;
          letter-spacing: -0.025em;
          line-height: 19px;
          margin-bottom: 1px;
          padding: 10px 12px;

          &.last-button {
            border-bottom: 1px solid var(--surface-2);
          }

          &:hover {
            background-color: var(--surface);
          }
        }
        .dwd-header-submenu-section-company-buttons,
        .dwd-header-submenu-section-contact-buttons {
          align-items: center;
          color: var(--title);
          display: flex;
          font-family: $font-red-hat;
        }
      }
    }

    &.dwd-header-submenu-solutions-old {
      left: -180px;
      min-width: 1054px;

      &.fr, &.de{
        min-width: 1100px;
      }

      .dwd-header-submenu-big-item:hover {
        background-color: var(--surface);
      }

      .dwd-header-submenu-big-item {
        @media (min-width: 1024px) and (max-width: 1279px) {
          justify-content: space-between;
        }
        min-height: 177px;
      }
    }

    &.dwd-header-submenu-support {
      img {
        width: 60px;
      }

      .fr-height {
        @media(min-width:600px){
          min-height: 258px;
        }
      }

      .dwd-header-submenu-section-support-container {
        display: block;
        width: 740px;

        @media (max-width: 1279px) {
          width: 100%;
        }

        .dwd-header-submenu-section-support {
          @media (min-width: 1024px) and (max-width: 1279px) {
            justify-content: space-between;
          }

          @media (max-width: 599px) {
            justify-content: center;
            width: 100%;
          }
          .big-size:nth-child(4) {
            margin-right: 0;
          }
          .big-size:nth-child(2),
          .big-size:nth-child(4) {
            @media (min-width: 600px) and (max-width: 1023px) {
              margin-right: -20px;
            }
          }
          .big-size {
            @media (max-width: 1023px) {
              margin-bottom: 10px;
            }
            @media (max-width: 599px) {
              margin: 0 0 10px 0;
            }
          }
        }
      }
      .dwd-header-submenu-section-resources-container {
        margin-left: 5px;

        @media (max-width: 1279px) {
          margin: 24px 0 50px;
        }
        @media (max-width: 599px) {
          padding: 0 16px;
        }
        .d-flex {
          flex-direction: column;
        }

        .dwd-header-submenu-title {
          margin-left: 10px;
        }

        .dwd-header-submenu-section-resources-buttons {
          border-top: 1px solid var(--surface-2);
          color: var(--title);
          padding: 10px;
          font-family: $font-red-hat;
          font-size: 16px;
          letter-spacing: -0.02em;
          line-height: 19px;
          min-width: 301px;

          @media (max-width: 599px) {
            min-width: unset;
          }

          &:hover {
            background-color: var(--surface);
          }

          &:nth-child(9) {
            border-bottom: 1px solid var(--surface-2);
          }
        }
      }
      .dwd-header-submenu-section-support-more-info {
        display: flex;
        margin-top: 24px;

        @media (max-width: 1023px) {
          flex-direction: column;
        }

        .dwd-header-submenu-section-more-info-container {
          margin-right: 24px;
          width: 350px;

          @media (min-width: 1024px) and (max-width: 1279px) {
            flex: 0 0 49%;
          }

          @media (max-width: 599px) {
            width: 100%;
            padding: 0 24px;
          }

          .dwd-header-submenu-section-contact-description {
            font-family: $font-red-hat;
            font-size: 10px;
            line-height: 15px;

            div{
              font-family: $font-red-hat;
            }
          }

          .dwd-header-submenu-section-contact-info {
            display: flex;
            font-family: $font-red-hat;
            margin-bottom: 8px;
            margin-top: 24px;

            a {
              color: var(--primary);
              &:hover, &:focus{
                color: var(--primary);
              }
            }
          }

          .dwd-header-submenu-section-more-info-title {
            border-bottom: 1px solid var(--surface-2);
            color: var(--title);
            font-family: $font-red-hat;
            font-size: 20px;
            letter-spacing: -0.98px;
            line-height: 15px;
            padding-bottom: 10px;

            &.fr-title {
              font-size: 18px;
            }
          }
          .dwd-header-submenu-section-more-info-description {
            font-family: $font-red-hat;
            font-size: 11px;
            line-height: 16px;
            margin-top: 24px;
            a {
              color: var(--primary);
              &:hover, &:focus{
                color: var(--primary);
              }
            }
          }
        }

        .dwd-header-submenu-section-more-info-container-2 {
          margin-right: 0;

          @media (max-width: 1023px) {
            margin-top: 24px;
          }
        }
      }

      .big-size {
        
        flex-wrap: wrap;
        
        min-width: 342px;
        max-width: 342px;
        width: 100%;
        

        &.de{
          max-height: 250px;
        }

        @media (min-width: 1024px) and (max-width: 1279px) {
          flex: 0 0 calc(50% - 10px);
          max-width: unset;
        }
        @media (max-width: 1023px) {
          margin: 0;
        }

        &:hover {
          background-color: var(--surface);
        }
      }

      .dwd-header-submenu-description {
        margin-top: 8px;
      }

      .dwd-header-submenu-section-header-title {
        letter-spacing: -0.98px !important;
        line-height: 20px !important;
        margin-left: 0 !important;
      }
    }

    &.dwd-header-submenu-products,
    &.dwd-header-submenu-support,
    &.dwd-header-submenu-company,
    &.dwd-header-submenu-solutions {
      flex-direction: row;
      left: -190px;
      min-width: 1146px;
    }

    &.dwd-header-submenu-products,
    &.dwd-header-submenu-support,
    &.dwd-header-submenu-company,
    &.dwd-header-submenu-solutions {
      @media (max-width: 1279px) {
        flex-direction: column;
        height: 100%;
        left: -900px;
        min-width: 900px !important;
        overflow: scroll;
        width: 80%;
      }
      @media (min-width: 1024px) and (max-width: 1279px) {
        top: 50px;
        left: 0;
        
        @media (orientation: portrait) { 
          height: unset;
        }
      }
      @media (max-width: 1023px) {
        min-width: 0 !important;
        width: 100%;
      }
    }

    &.dwd-header-submenu-solutions {
      @media(max-width: 1279px) {
        flex-direction: column;
      }
      @media(max-width: 1023px) {
        padding-top: 0;
      }

      &.dwd-header-submenu.dwd-header-submenu-mobile {

        @media(max-width: 1279px) {
          height: 100%;
          flex-direction: column;
        }
      }

      .dwd-header-submenu-title.solutions-version {
        margin-left: 32px;
      }

      .dwd-header-submenu-section-products{
        justify-content: end;
      }

      .dwd-header-submenu-big-item.allSolutions {
        background-color: var(--primary);
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-bottom: 30px;
        max-width: 312px;
        min-width: 312px;
        
        &:hover {
          background-color: var(--primary-hover);
        }

        .margin-top-auto- {
          margin-top: auto;
        }

        .margin-top-auto-de-margin {
          margin-top: 60px;
          margin-bottom: -10px;
        }

        .dwd-header-submenu-section-header-title {
          color: var(--primary-text);
          font-size: 28px;
          font-weight: bold;
          line-height: 30px;
          margin-bottom: 12px;
          margin-left: 0;
          max-width: 140px;
          width: 100%;
        }

        .description {
          color: var(--primary-text);
          font-size: 14px;
        }
      }

      .dwd-header-submenu-section-companion-tools-container {
        @media(max-width: 1279px) {
          margin-bottom: 0 !important;
          order: 1;
        }
      }

      .dwd-header-submenu-back {
        position: relative;
        margin-left: -10px;
        padding-top: 0;
        padding-left: 0;
        left: 0;

        @media(max-width: 1023px) {
          margin-left: 0;
          padding-left: 15px;
          padding-top: 15px;
        }
      }

      .dwd-header-submenu-section-products-container {
        order: 2;
      }

      .dwd-header-submenu-logo {
        align-items: center;
        height: 80px;
      }
    }

    &.dwd-header-submenu-products, &.dwd-header-submenu-solutions, &.dwd-header-submenu-support {
      .dwd-header-submenu-section-products-container {
        width: 100%;

        @media (max-height: 1279px) {
          margin-bottom: 20px;
        }

        .dwd-header-submenu-section-products {
          @media (min-width: 1024px) and (max-width: 1279px) {
            justify-content: space-between;
          }
          .devolutions:hover {
            background-color: #eaf5ff99;
            border: 1px solid transparent;
          }
          .password:hover {
            background-color: #fff5eb99;
            border: 1px solid transparent;
          }
          .server:hover {
            background-color: #fff2f299;
            border: 1px solid transparent;
          }
          .gateway:hover {
            background-color: #f2edf599;
            border: 1px solid transparent;
          }
          .pam:hover {
            border: 1px solid rgba(234,247,226,.6);
            background-color: rgba(234,247,226,.6);
          }  
          .free:hover {
            background-color: #eaf5ff99;
            border: 1px solid transparent;
          }  
        }

        .dwd-header-submenu-section-header-title {
          font-size: 16px;
          letter-spacing: -0.02em;
          margin-left: 0;
          min-width: 250px;

          @media (min-width: 1024px) and (max-width: 1279px) {
            max-width: unset;
            width: 100%;
          }
        }
        .dwd-header-submenu-big-item {
          min-height: 166px;

          @media (min-width: 1024px) and (max-width: 1279px) {
            flex: 0 0 calc(50% - 10px);
            max-width: unset;
          }

          @media (max-width: 1023px) {
            margin: 0 0 0px;
          }
          .dwd-header-submenu-logo {
            display: flex;
            margin-bottom: 12px;
            img {
              max-height: 60px;
            }
            .text {
              color: var(--primary);
              font-family: "Red Hat Display", sans-serif;
              font-size: 20px;
              letter-spacing: -0.02em;
              line-height: 18px;
              max-width: 200px;
              margin-left: 20px;
              word-break: initial;
              display: flex;
              align-items: center;

              &:lang(de) {
                @media (max-width: 599px) {
                  font-size: 17px; 
                }
              }
            }
          }
          .description {
            color: var(--title);
            font-size: 11px;
            letter-spacing: 0;
          }
        }
      }

      .dwd-header-submenu-description {
        font-family: $font-red-hat;
        letter-spacing: 0.32px;
        margin-top: 12px;
        text-transform: uppercase;
      }
      .dwd-header-submenu-section-companion-tools-container {
        width: 310px;

        @media (max-width: 1279px) {
          margin-bottom: 50px;
        }
        @media (min-width: 1024px) and (max-width: 1279px) {
          width: 100%;
        }
        @media(max-width: 599px) {
          width: 303px;
        }
        .d-flex {
          @media (max-width: 599px) {
            padding: 0 16px;
            justify-content: center;
          }
        }

        .dwd-header-submenu-section-companion-tools-buttons {
          align-items: center;
          border-top: 1px solid var(--surface-2);
          display: flex;
          height: 88px;
          padding: 10px;
          width: 306px;

          @media (min-width: 1024px) and (max-width: 1279px) {
            width: 100%;
          }

          &:hover {
            background-color: var(--surface);
          }

          &:last-child {
            border-bottom: 1px solid var(--surface-2);
          }
          .dwd-header-submenu-logo {
            display: flex;
            flex: 1 1 100%;
            @media (min-width: 1024px) and (max-width: 1279px) {
              max-width: 270px;
            }
            img {
              max-height: 50px;
              width: auto;
            }
            .text {
              display: flex;
              align-items: center;
              font-family: "Red Hat Display", sans-serif;
              font-size: 20px;
              letter-spacing: -0.02em;
              line-height: 18px;
              max-width: 200px;
              margin-left: 10px;
              color: #000;
              
              @media (max-width: 599px) {
                font-size: 18px;
              }

              &:lang(de) {
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
                word-break: break-word;

                &.no-break {
                  word-break: unset;
                  hyphens: none;
                  -webkit-hyphens: none;
                  -moz-hyphens: none;
                  -ms-hyphens: none;
                }
              }
            }
          }
          .dwd-header-submenu-section-companion-tools-description {
            color: var(--title);
            font-family: $font-red-hat;
            font-size: 14px;
            letter-spacing: -0.69px;
            line-height: 16px;
            margin-left: auto;
            max-width: 130px;
            flex: 1 1 100%;
            padding-left: 10px;
            @media (min-width: 1024px) and (max-width: 1279px) {
              max-width: unset;
            }
            @media (max-width: 599px) {
              max-width: 100px;
            }
          }
        }
      }
    }
    &.dwd-header-submenu-products .dwd-header-submenu-section-companion-tools-container .dwd-header-submenu-section-companion-tools-buttons .dwd-header-submenu-logo {
      @media (min-width: 1024px) and (max-width: 1279px) {
        max-width: 180px;
      }
    }

    .dwd-header-submenu-back {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: none;
      font-size: 0;
      left: 12px;
      padding: 15px;
      position: absolute;
      top: 0;

      @media (max-width: 1023px) {
        display: block;
      }
    }

    .dwd-header-submenu {
      box-shadow: none;
      display: flex;
      left: 0;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: auto;

      > :first-child {
        margin-top: -70%;
        transition: margin-top 0.6s ease;
      }

      &.active {
        > :first-child {
          margin-top: 14px;
        }
      }
    }

    &.active {
      display: flex;
    }

    > span {
      align-items: center;
      border-bottom: 1px solid var(--surface-2);
      display: flex;
      font-weight: 700;
      height: 60px;
      letter-spacing: -0.05em;
      padding: 10px 40px;
      text-transform: uppercase;

      @media (max-width: 1279px) {
        padding: 10px 25px;
      }
    }

    .dwd-header-submenu-item {
      align-items: center;
      border: none;
      color: var(--title);
      display: flex;
      height: 60px;
      letter-spacing: -0.05em;
      padding: 10px 40px;
      white-space: nowrap;
      width: 100%;

      @media (max-width: 1279px) {
        padding: 10px 25px;
      }

      @at-root {
        #{selector-unify(&, button)} {
          background-color: var(--title);
          color: var(--primary-text);
          font-weight: 700;
          margin-top: 10px;
          margin-left: -48px;
          padding-left: calc(40px + 48px);
          padding-right: 48px;
          text-transform: uppercase;
          width: calc(100% + (48px * 2));

          @media (max-width: 1279px) {
            margin-left: -30px;
            padding-left: calc(25px + 30px);
            width: calc(100% + (30px * 2));
          }

          @media (max-width: 599px) {
            margin-left: 0;
            padding-left: 25px;
            width: 100%;
          }

          &:hover {
            background-color: var(--title);
            color: var(--primary-text);
            cursor: pointer;
          }

          svg {
            height: 24px;
            margin-left: auto;
            width: 24px;
          }
        }
      }

      &:hover {
        background-color: #f8f8f8;
        color: var(--primary);
      }

      + .dwd-header-submenu-item {
        border-top: 1px solid var(--surface-2);
      }

      svg,
      img {
        height: 32px;
        margin-right: 10px;
        max-width: unset;
        width: 32px;
      }

      .dvl-icon-dot {
        height: 14px;
      }
    }
  }

  .dwd-header-backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    display: none;
    height: 100%;
    position: fixed;
    right: 0;
    top: 52px;
    width: 100%;
    z-index: 999;
  }
}
