@use 'variables' as *;
@use 'theme' as *;

#search-icon-right {
  background-color: transparent;
  border: none;
  color: #ffffff;
  cursor: pointer;
  margin-left: auto;
  padding: 8px;

  svg {
    display: flex;
    align-items: center;
    height: 32px;
    width: 32px;
  }
}

.search {
  display: flex;
  color: var(--navbarForeground);
  cursor: pointer;

  svg {
    display: flex;
    align-items: center;
    height: 32px;
    width: 32px;
  }
}