@use 'variables' as *;
@use 'theme' as *;
@use 'fonts' as *;

.tabs {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin: 16px 0;

  @media (max-width: var(--md)) {
    display: block;
  }
}

.tabs-titles {
  display: flex;
  flex-direction: column;
  max-width: 270px;
  min-width: 180px;

  @media (max-width: var(--md)) {
    display: none;
  }

  > * {
    flex: 1;
    outline: none;
    background-color: var(--tabTitlesBackground);
    padding: 14px 20px;
    border: 1px solid white;
    border-right: 0;
    border-radius: 5px 0 0 5px;
    color: var(--primary);
    text-align: left;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    cursor: pointer;

    &:first-child {
      border-top: 1px solid #f0f0f0;
    }

    &.active {
      background-color: #f8f8f8;
    }

    &:hover {
      background: transparent;
      text-decoration: underline;
      border-color: transparent;
    }
  }

  button {
    flex: 1;
    outline: none;
    background-color: var(--tabTitlesBackground);
    padding: 14px 20px;
    border: 1px solid white;
    border-right: 0;
    border-radius: 5px 0 0 5px;
    color: var(--primary);
    cursor: pointer;
    text-align: left;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    width: 100%;

    &:first-child {
      border-top: 1px solid #f0f0f0;
    }

    &.active {
      background-color: #f8f8f8;
    }

    &:hover {
      background: transparent;
      text-decoration: underline;
      border-color: transparent;
    }
  }
}

.tabs-content {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 100%;
  flex: 1;

  > div {
    &.active {
      height: 100%;
    }

    .tab-title {
      display: none;

      @media (max-width: var(--md)) {
        display: flex;
      }
    }

    .tab-contents {
      flex: 1;
      height: 100%;
      border: 1px solid #f0f0f0;
      background-color: #f8f8f8;
      border-top-left-radius: 0;
      align-self: stretch;
      padding: 0 20px;
      display: none;

      &.active {
        display: flex;
      }
    }
  }
}
