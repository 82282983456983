.dwd-author-header {
  &-title {
    border-bottom: 1px solid #e0e0e0;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: -0.03em;
    line-height: 36px;
    margin: 0;
    padding-bottom: 34px;
  }

  &-content {
    align-items: flex-start;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    padding: 30px 0 34px;
    margin-bottom: 82px;

    &-avatar {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      line-height: 0;
      max-width: 192px;
      padding: 6px;
      width: 100%;

      picture,
      img {
        width: 100%;
      }
    }

    &-biography {
      letter-spacing: -0.8px;
      line-height: 28px;
      padding: 6px 0 0 38px;

      > * {
        margin-top: 0;
      }
    }
  }
}
