.dwd-pagination {
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px 0;
  margin-top: 30px;
  margin-bottom: 82px;

  > span, > a {
    align-items: center;
    border-radius: 4px;
    color: var(--onBackground);
    display: flex;
    justify-content: center;
    min-width: 48px;
    padding: 6px 8px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }

  > a {
    cursor: pointer;
  }

  > span {
    color: rgba(0, 0, 0, 0.26);

    &:hover {
      background-color: transparent;
    }
  }

  > .active {
    background-color: var(--primary);
    color: var(--onPrimary);
    pointer-events: none;

    &:hover {
      background-color: var(--primary);
      color: var(--onPrimary);
    }
  }
}