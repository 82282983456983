@use 'variables' as *;
@use 'theme' as *;
@use 'fonts' as *;

.latest-news {
  background-color: #000000;
  display: flex;
  margin: 122px 0 0 0;
  padding: 34px 24px 34px 120px;
  position: relative;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 100px;
    padding-right: 120px;
  }

  @media (max-width: 767px) {
    padding: {
      left: 34px;
      right: 34px;
    }
  }

  &-left {
    flex: 0 0 60%;
    max-width: 60%;

    @media (max-width: 1024px) {
      display: flex;
      flex: 1;
      flex-direction: column;
      max-width: none;

      > * {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
      }
    }
  }

  &-right {
    flex: 0 0 40%;
    max-width: 40%;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &-title {
    color: var(--onPrimary);
    font-family: var(--header);
    font-size: 36px;
    line-height: 38px;
    font-weight: 400;
    margin: 0 0 12px;
    text-align: left;
    letter-spacing: -1.8px;

    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  &-image {
    max-width: 350px;
    position: absolute;
    top: -80%;
    left: 0;
    width: 100%;
  }

  form {
    display: flex;

    @media (max-width: 640px) {
      flex-direction: column;
    }
  }

  input {
    background: #fff;
    border: 1px solid #ffffff;
    border-radius: 6px;
    /* color: rgba(0, 0, 0, 0.6); */
    padding: 13px 16px;
    flex: 1;
    font-size: 16px;
    font: normal normal 600 16px/27px Open Sans;
    letter-spacing: -0.8px;
    color: #bdbdbd;
    font-weight: 600;
    max-width: 458px;
    outline: 0;
    -moz-transition: border-color 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: border-color 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transition: border-color 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: border-color 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    @media (max-width: 640px) {
      max-width: 100%;
    }

    &:focus {
      border-color: #0068c3;
    }
  }

  button {
    background-color: #0068c3;
    border: 1px solid #0068c3;
    border-radius: 6px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    margin-left: 8px;
    padding: 13px 24px;
    text-transform: uppercase;
    -moz-transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    @media (max-width: 640px) {
      margin: {
        left: 0;
        top: 8px;
      }
    }

    &:hover {
      background-color: #00539c;
      border-color: #00539c;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  p {
    color: #bdbdbd;
    font-size: 14px;
    line-height: 1;
    margin: 12px 0 20px 0;
  }
}
