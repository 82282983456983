.blog-category {
  h1 {
    font-family: var(--default);
    font-size: 32px;
    letter-spacing: -1.12px;
    line-height: 36px;
  }

  &__header {
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 30px;
    margin-bottom: 24px;
  }

  &__description {
    color: #707070;
    display: flex;
    margin-top: 24px;
    max-width: 800px;

    img {
      border: 2px solid #ffffff;
      box-shadow: 0 3px 6px #00000029;
      height: 60px;
      margin-right: 20px;
      width: 60px;
    }
  }
}
