.d-flex {
  display: flex !important;
}

.d-none {
  display: none;
}

.flex-wrap {
  flex-wrap: wrap;
}