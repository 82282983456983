@use 'variables' as *;
@use 'theme' as *;

.category-title {
  display: flex;
  width: 100%;

  &.desc-title {
    flex-direction: column;
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
    margin-bottom: 24px;
    h1 {
      padding-bottom: 0; 
      border-bottom: none;
    }
    .description-container {
      display: flex;

      .desc-avatar {
        height: 60px;
        width: 60px; 
        margin-right: 20px; 
        box-shadow: 0px 3px 6px #00000029;
        border: 2px solid #FFFFFF;
      }

      p {
        color: #707070;
        max-width: 740px;
        padding-bottom: 30px; 
      }
      
    }
  
  }

  h1 {
    font-family: var(--default);
    font-size: 32px;
    line-height: 36px;
    font-weight: bold;
    margin-bottom: 24px;
    padding-bottom: 30px;
    letter-spacing: -1.12px;
    text-align: left;
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  }

  .h1 {
    color: var(--onSecondary);
    font-family: var(--default);
    font-size: 32px;
    line-height: 36px;
    font-weight: bold;
    margin-bottom: 24px;
    padding-bottom: 30px;
    letter-spacing: -1.12px;
    text-align: left;
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  }
}



.article-category-shape {
  @each $category, $color in $categories {
    &.category-#{$category} {
      font-family: var(--default);
      font-size: 1.1em;
      line-height: 1.5em;
      font-weight: 600;
      color: white;
      padding: 0 12px;
      margin-right: 30px;
      letter-spacing: -0.56px;
      margin-bottom: 12px;
      text-transform: uppercase;
      background: $color;
    }
  }
}

.articles-item {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  & + * {
    margin-top: 24px;
  }

  & + & {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 24px;
  }

  > *:first-child {
    flex: 0 0 33.33%;
    max-width: 33.33%;

    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  > *:last-child {
    flex: 1;
    margin-left: 20px;

    @media (max-width: 767px) {
      margin-left: 0;
      margin-top: 16px;
      padding-left: 0;
    }
  }

  .hero-image {
    display: block;
    font-size: 0;
    margin-bottom: 0;
    max-width: 100%;
    transition: all 0.25s linear;

    &:hover {
      opacity: 0.6;
    }
  }

  .articles-item-title {
    color: var(--onSurface);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.03em;
    line-height: 30px;
    margin-bottom: 12px;
    margin-top: 0;
    letter-spacing: -1.2px;

    a {
      color: var(--onSurface);
    }

    a:hover {
      color: var(--primary);
    }

    + * {
      margin-top: 12px;
    }
  }

  .article-excerpt-break {
    word-break: break-word;
    min-height: 3.5em;
    text-overflow: ellipsis;
    letter-spacing: -0.8px;
    display: -webkit-box;

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 0;

      + * {
        margin-top: 8px;
      }
    }
  }
}

.article-author-date {
  color: var(--team);
  align-items: center;
  display: flex;
  line-height: 1;

  & + * {
    margin-top: 12px;
  }

  .article-author,
  .article-author a,
  .article-datetime {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 12px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    + * {
      margin-left: 10px; 
    }
  }

  .article-author {
    color: var(--team);

    // @media (max-width: 767px) {
    //   padding-left: 18px;
    // }
  }

  .article-datetime {
    color: var(--datetime);

    &:hover {
      text-decoration: none;
    }
  }
}

.paginator {
  display: flex;
  justify-content: center;
  margin-top: 38px;

  > span,
  > a {
    align-items: center;
    border-radius: 4px;
    color: var(--onBackground);
    display: flex;
    justify-content: center;
    min-width: 48px;
    padding: 6px 8px;
    text-align: center;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }

    svg {
      height: 18px;
      width: 18px;
    }
  }

  > span {
    color: rgba(0, 0, 0, 0.26);

    &:hover {
      background-color: transparent;
    }
  }

  > .active {
    background-color: var(--primary);
    color: var(--onPrimary);
    pointer-events: none;

    &:hover {
      background-color: var(--primary);
      color: var(--onPrimary);
    }
  }
}

.bottom-pad {
  padding-bottom: 50px;
}