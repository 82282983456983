.articles {
  display: flex;
  flex-direction: row;
  margin: 0 -15px 60px;

  > *:last-child {
    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 599px) {
    flex-direction: column;

    .article-body {
      margin-right: 0;
    }
  }

  > * {
    @media (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @media (max-width: 599px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    + * {
      margin-top: 0;

      @media (max-width: 599px) {
        margin-left: 0;
        margin-top: 24px;
      }
    }
  }
}
