html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body {
  color: #494949;
  font-family: proxima-nova;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.4;
  margin: 0;
  letter-spacing: 0.02em
}

*,
*:before,
*:after {
  box-sizing: inherit;
  scroll-margin-top: calc(72px + 24px);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(33, 33, 33);
  font-family: var(--default);
  letter-spacing: -0.0325em;
}

a {
  text-decoration: none;
}

svg {
  fill: currentColor;
  height: 24px;
  width: 24px;
}

.web-site-width {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(1200px + 80px);
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;

  @media (max-width: 1024px) {
    max-width: calc(1200px + 40px);
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 719px) {
    max-width: calc(1200px + 32px);
    padding-left: 16px;
    padding-right: 16px;
  }
}
