@use 'variables' as *;
@use 'theme' as *;
@use 'fonts' as *;

.quiz {
  margin: 24px auto 48px !important;
  max-width: 900px;
  position: relative;

  .blog-quiz-question-container {
    background-color: #f5f5f5;
    border-radius: 20px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, .24);
    padding: 36px;
    position: relative;
  }

  .blog-quiz-progression {
    font-size: 24px;
    text-align: center;

    span:first-child {
      color: var(--primary);
    }
  }

  .blog-quiz-footer {
    border-top: 1px solid rgba(0, 0, 0, .12);
    display: flex;
    justify-content: flex-end;
    max-width: 700px;
    margin: 16px auto -16px;
    padding-top: 16px;

    * + * {
      margin-left: 8px;
    }
  }

  .blog-quiz-answers-tracker {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 700px;
    position: relative;
    top: 16px;

    span {
      background-color: #bdbdbd;
      border-radius: 4px;
      display: block;
      height: 8px;
      margin: 5px;
      width: 8px;

      &.active {
        border-radius: 6px;
        height: 12px;
        width: 12px;
      }

      &.correct {
        background-color: #61aa30;
      }

      &.wrong {
        background-color: #ff1a1a;
      }
    }
  }

  .blog-quiz-question {
    > * {
      margin-left: auto;
      margin-right: auto;
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 700px;
    }

    .blog-quiz-question-question {
      flex: 0 0 100%;
      font-size: 28px;
      margin: 24px auto;
      max-width: 700px;
      text-align: center;
    }

    .blog-quiz-question-answer-container {
      max-width: 700px;
      width: 100%;

      &:not(.answered) {
        .blog-quiz-question-answer {
          cursor: pointer;

          &:hover {
            background-color: var(--primary);
            color: var(--background);
          }
        }
      }
    }

    .blog-quiz-question-answer {
      background-color: var(--background);
      border: 1px solid #bdbdbd;
      border-radius: 6px;
      cursor: default;
      display: block;
      font-size: 18px;
      font-weight: normal;
      margin-top: 8px;
      padding: 13px 16px;
      text-align: left;
      transition: all .25s ease;
      width: 100%;

      &.active {
        background-color: #ffdede;
      }

      &.perfect {
        background-color: #61aa30;
        color: var(--background);
      }

      *:first-child {
        margin-top: 0;
      }

      *:last-child {
        margin-bottom: 0;
      }
    }
  }

  .blog-quiz-results {
    display: flex;
    flex-wrap: wrap;
    margin-top: -8px;
    margin-left: -8px;
    margin-right: -8px;

    .blog-quiz-result {
      background-color: #f5f5f5;
      border-radius: 10px;
      color: rgba(var(--onBackground), .3);
      flex: 1 1 0;
      margin-top: 8px;
      margin-left: 8px;
      margin-right: 8px;
      padding: 24px 12px;
      text-align: center;

      @media (max-width: var(--md)) {
        flex: 1 1 100%;
      }

      &.active {
        background-color: var(--primary);
        color: var(--onPrimary);

        h3 {
          color: var(--background);
        }

        .icon {
          color: var(--onBackground);

          &.active {
            color: var(--background);
          }
        }
      }

      &.result-1 {
        margin-top: 32px;

        @media (max-width: var(--md)) {
          margin-top: 8px;
        }
      }

      &.result-2 {
        margin-top: calc(32px * 2);

        @media (max-width: var(--md)) {
          margin-top: 8px;
        }
      }

      &.result-3 {
        margin-top: calc(32px * 3);

        @media (max-width: var(--md)) {
          margin-top: 8px;
        }
      }

      &.result-4 {
        margin-top: calc(32px * 4);

        @media (max-width: var(--md)) {
          margin-top: 8px;
        }
      }

      h3 {
        color: var(--onBackground)
      }

      p {
        text-align: center;
      }

      *:last-child {
        margin-bottom: 0;
      }

      .blog-quiz-result-result {
        font-size: 12px;
        text-transform: uppercase;
      }

      .icon {
        color: var(--onBackground);
        height: 18px;
        width: 18px;

        &.active {
          color: var(--onBackground);
        }
      }
    }
  }
}