$primary: #0068c3 !default;
$default: proxima-nova, sans-serif;
$header: proxima-nova, sans-serif;
$oswald: Oswald, sans-serif;
$mono: IBM Plex Mono, monospace;
$font-red-hat:'Red Hat Display', sans-serif !default;
$primary: #0068c3;
$primaryVariant: #3700b3;
$secondary: #03dac6;
$secondaryVariant: #018786;
$background: #ffffff;
$surface: #ffffff;
$error: #b00020;
$borderSurface: #e5e5e5;
$onPrimary: #ffffff;
$onSecondary: #000000;
$onBackground: #424242;
$onSurface: #000000;
$onError: #ffffff;
$spiceworks: #ff7f32;
$reddit: #ff4500;
$youtube: #ff0000;
$twitter: #1da1f2;
$linkedin: #0a66c2;
$facebook: #4267b2;
$instagram: #262626;
$rss: #f26522;

$spacing: 0, 2, 8, 12, 16, 24, 48, 50, 72, 100, 120;
$max-widths: 1000, 900, 800, 700, 630, 400;

$categories: (
  'customer-stories': #ff9933,
  'news': #3399ff,
  'tips-and-tricks': #99cc33,
  'poll': #ffcc66,
  'sysadminotaur': #ff6633,
  'fun-lifestyle': #cc33ff,
  'products': #33cc33,
  'review': #339999,
  'team': #0068c3,
  'whitepapers': #993399,
  'security': #ff0033,
  'adventures-of-a-cto': #33CCCC,
);

$socialMedias: (
  'socialSpiceworks': #ff7f32,
  'socialReddit': #ff4500,
  'socialYoutube': #ff0000,
  'socialTwitter': #1da1f2,
  'socialLinkedin': #0a66c2,
  'facebook': #4267b2,
  'socialInstagram': #e1306c,
  'rss': #f26522,
);
