@use 'variables' as *;
@use 'theme' as *;

.navbar {
  align-items: center;
  display: flex;
  padding: 19px 0;
  position: relative;
  height: inherit;

  nav {
    display: flex;
    transition: all 0.25s ease-in-out;
  }
}

.navbar-fluid {
  height: 72px;
  margin-bottom: 53px;
  background-color: var(--navbarBackground);
}

.navbar-link {
  color: var(--navbarForeground);
  font-family: var(--default);
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: 0.8;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;

  &:hover,
  &.active {
    opacity: 1;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      opacity: 1;
      border-radius: 3px;
      width: 100%;
      background-color: var(--navbarBackground);
      height: 6px;
      bottom: -5px;
    }

    &.active,
    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &.category-all {
      &:hover,
      &.active {
        transition: none;
      }
      &::after {
        background-color: var(--all);
      }
    }

    @each $category, $color in $categories {
      &.category-#{$category} {
        &::after {
          background-color: $color;
        }
      }
    }

    &.hide {
      display: none;
    }
  }
  &.more {
    opacity: 1;
  }
  .category-more {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    > * {
      pointer-events: none;
    }
    .mobile {
      display: none;
    }
    @media (max-width: 600px) {
      span {
        display: none;
        &.mobile {
          display: flex;
        }
      }
    }
  }
  .category-more-menu {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 100px;
    padding: 16px 24px;
    position: absolute;
    top: calc(100% + 11px);
    right: 0;
    background-color: black;
    opacity: 1;
    z-index: 2;
    &.show {
      display: flex;
      opacity: 1;
    }
    a {
      height: 100%;
      width: auto;
      color: var(--navbarForeground);
      opacity: 0.8;
      margin: 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      &::after {
        height: 2px
      }
      &:hover,
      &.active {
        opacity: 1;
      }
      + a {
        padding-top: 12px;
      }
    }
    @media (max-width: 600px) {
      left: -20px;
      width: 100vw;
    }
  
  }

  svg {
    height: 21px;
    width: 21px;
    padding-bottom: 4px;
  }

  &.hide {
    display: none;
  }

  + * {
    margin-left: 16px;
    transition: none;
  }
}
