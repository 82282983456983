@use 'variables' as *;

.article {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 calc(33.33%);
  padding-left: 15px;
  padding-right: 15px;
  position: relative;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0;

    + * {
      margin-top: 8px;
    }
  }

  img {
    height: auto;
    min-width: 100%;
  }

  & + * {
    margin-top: 24px;
  }

  > *:first-child {
    flex: 0 0 67.5%;
    max-width: 67.5%;
    margin-right: 30px;

    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-right: 0;
    }
  }

  > *:last-child {
    flex: 1;

    @media (max-width: 767px) {
      margin-left: 0;
      margin-top: 16px;
      padding-left: 0;
      margin-right: 0;
    }
  }

  .hero-image {
    display: block;
    font-size: 0;
    margin-bottom: 0;
    max-width: 100%;
    transition: all 0.25s linear;

    &:hover {
      opacity: 0.8;
    }
  }

  .main-article-category {
    @each $category, $color in $categories {
      &.category-#{$category} {
        font-family: var(--default);
        font-size: 1.3em;
        line-height: 1.5em;
        font-weight: 600;
        color: white;
        padding: 0 12px;
        margin-right: 35px;
        margin-bottom: 12px;
        text-transform: uppercase;
        background: $color;
      }
    }
  }

  .main-article-title {
    color: var(--onSurface);
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    line-height: 43px;
    margin-bottom: 24px;
    margin-top: 0;
    letter-spacing: -1.68px;
    text-align: left;
    padding-right: 20px;

    a {
      color: var(--onSurface);
    }

    a:hover {
      color: var(--primary);
    }

    .main-article-title + * {
      margin-top: 12px;
    }

    @media (max-width: 767px) {
      font-size: 44px;
      letter-spacing: -1.8px;
      padding-left: 15px;
    }
  }

  .article-title {
    font-size: 28px;
    font-weight: 600;
    font-style: normal;
    line-height: 30px;
    letter-spacing: -1.4px;
    margin: 16px 0 12px 0;
    text-align: left;

    a {
      color: var(--onSurface);
      cursor: pointer;
    }

    a:hover {
      color: var(--primary);
    }
  }

  .article-author-date {
    align-items: center;
    display: flex;
    line-height: 1;

    & + * {
      margin-top: 12px;
    }

    .text-body {
      display: block;
    }

    .article-author,
    .article-author a,
    .article-datetime {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 12px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      + * {
        margin-left: 10px; 
      }
    }

    .article-author {
      color: var(--team);
    }

    .article-datetime {
      color: var(--datetime);
      font-weight: 400;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .article-category-body {
    display: flex;
    position: absolute;
    z-index: 1;

    > *:last-child {
      flex: 1;

      @media (max-width: 767px) {
        margin-left: 0;
        margin-top: 0;
        padding-left: 0;
      }
    }

    @media (max-width: 991px) {
      max-width: calc(100% - 32px);
      left: 15px;
    }
  }

  .article-category {
    align-items: center;
    display: flex;
    line-height: 1;
    text-transform: capitalize;

    & + * {
      margin-top: 10px;
      margin-left: -4px;
    }

    @each $category, $color in $categories {
      &.category-#{$category} {
        display: inline-block;
        font-family: var(--default);
        font-weight: 600;
        font-size: 1em;
        line-height: 1.5em;
        color: white;
        padding: 1px 12px;
        margin-bottom: 12px;
        text-transform: uppercase;
        background: $color;
        letter-spacing: -0.56px;
      }
    }
  }

  .article-excerpt-break {
    word-break: break-word;
    height: 3.5em;
    text-overflow: ellipsis;
    letter-spacing: -0.8px;
    display: -webkit-box;

    &-plus {
      margin: 30px 0;
    }
  }
}
