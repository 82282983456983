@import url('https://use.typekit.net/upp6rus.css');

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/ibm-plex-mono-v19-latin-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/red-hat-display-v19-latin-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/red-hat-display-v19-latin-700.woff2') format('woff2');
}