// Transition

// Transition
@mixin transition($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

// Transition Delay
@mixin transition-delay($delay...) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  transition-delay: $delay;
}

// Transition Duration
@mixin transition-duration($duration...) {
  -webkit-transition-duration: $duration;
  -moz-transition-duration: $duration;
  -o-transition-duration: $duration;
  transition-duration: $duration;
}

// Transition Property
@mixin transition-property($property...) {
  -webkit-transition-property: $property;
  -moz-transition-property: $property;
  -o-transition-property: $property;
  transition-property: $property;
}

// Transition Timing Function
@mixin transition-timing-function($timing-function...) {
  -webkit-transition-timing-function: $timing-function;
  -moz-transition-timing-function: $timing-function;
  -o-transition-timing-function: $timing-function;
  transition-timing-function: $timing-function;
}
