.carousel {
  display: flex;
  flex-direction: column;
  margin: 70px auto 0 auto;
  padding: 0 100px;
  overflow: hidden;

  @media (max-width: 719px) {
    display: none;
  }

  .slides-container {
    position: relative;

    &:hover {
      .carousel-control {
        color: #e8e8e8;
      }
    }

    .slide {
      position: absolute;
      transition: transform 1s;
      display: flex;
      opacity: 0;
      top: 0;
      pointer-events: none;
      max-width: 800px;
      width: 100%;

      &.slide-right {
        transform: translateX(calc(100% + 100px));
        opacity: 1;
      }

      &.slide-left {
        transform: translateX(calc(-100% - 100px));
        opacity: 1;
      }

      &.active {
        transition: transform 1s;
        position: relative;
        opacity: 1;
        pointer-events: visible;
        cursor: pointer;

        &.slide-right {
          transition: transform 0s;
          transform: translateX(calc(100% + 100px));
        }

        &.slide-left {
          transition: transform 0s;
          transform: translateX(calc(-100% - 100px));
        }
      }
    }

    img {
      width: 100%;
      vertical-align: middle;
      box-shadow: 0 12px 30px 0 rgb(0 0 25 / 35%);
    }

    .carousel-control {
      align-items: center;
      background-color: transparent;
      border: none;
      color: transparent;
      cursor: pointer;
      display: flex;
      height: 100%;
      opacity: 1;
      padding: 0 25px;
      position: absolute;
      top: 0;
      transition: 0.6s ease;
      user-select: none;

      &:hover {
        color: #000 !important;
      }

      svg {
        height: 50px;
        width: 50px;
      }
    }

    .next {
      right: -100px;
    }

    .prev {
      left: -100px;
    }

    .caption {
      position: absolute;
      padding: 10px 40px;
      bottom: 21px;
      z-index: 10;
      text-align: center;
      background-color: #323232;
      box-shadow: 0 5px 10px rgb(0 0 0 / 35%);
      left: 50%;
      width: 100%;
      max-width: 450px;
      transform: translateX(-50%);
      color: #ffffff;

      * {
        margin-bottom: 0;

        + * {
          margin-top: 16px;
        }
      }

      a {
        color: white;
        text-decoration: underline;
      }
    }
  }

  .carousel-indicators {
    width: 100%;
    display: flex;
    justify-content: center;

    .carousel-indicator {
      display: flex;
      cursor: pointer;
      transition: background-color 0.6s ease;
      max-width: 30px;
      flex: 1 0 auto;
      margin: 20px 3px;
      height: 3px;
      width: 100%;
      background-color: #999999;

      &.active,
      &:hover {
        background-color: #0068c3;
      }
    }
  }
}
