@use 'variables' as *;
@use 'theme' as *;
@use 'fonts' as *;

#bio {
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, visibility 0s 0.5s, height 0.5s ease;

  &.active {
    opacity: 1;
    visibility: visible;
    height: auto;
    transition: opacity 0.5s ease, visibility 0s 0s, height 0.5s ease;
  }
}

#social-media {
  display: none;
}

.new-blog-grid {

  display: grid;
  grid-gap: 10px 20px;
  grid-template-rows: 50px auto auto 1fr auto auto auto;
  grid-template-columns: 2fr 0.75fr 1.25fr;
  grid-template-areas: "category category links "
                       "title heroImg heroImg "
                       "description  heroImg heroImg "
                       "author heroImg heroImg "
                       "bio bio bio "
                       "content content toc "
                       "bottom bottom bottom ";

  &.no-toc {
    @media (min-width: 729px) {
      grid-template-areas: "category category links "
                        "title heroImg heroImg "
                        "description  heroImg heroImg "
                        "author heroImg heroImg "
                        "bio bio bio "
                        "content content content "
                        "bottom bottom bottom ";

      .article-page-content {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        .content-container {
          max-width: 1000px;
        }
      }
    }

  }


  .article-page-category-title {
    grid-area: category;
    position: relative;

    span {
      font-size: 24px;
      font-weight: 700;

      @each $category, $color in $categories {
        &.category-#{$category} {
          color: $color;
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #E0E0E0;
      grid-column: 1 / 3;
      margin-bottom: 10px;
    }


  }
  .article-page-links {
    grid-area: links;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    @media (min-width: 769px) {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -20px;
        right: 0;
        height: 1px;
        background-color: #E0E0E0;
        margin-bottom: 10px;
      }
      padding-bottom: 10px;
    }

    button {
      background: transparent;
      border: none;
      display: flex;
      padding: 12px;
    }

    svg {
      color: var(--onSecondary);
      font-size: 0;
    }

    svg:hover {
      color: var(--team);
    }

    .article-page-header-right-share {
      align-items: center;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;

      &-media > svg:hover {
        color: var(--onPrimary);
      }

      .social-media {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 5;

        @media (max-width: var(--md)) {
          flex-direction: row;
          position: relative;
        }

        a {
          background-color: #ffffff !important;
          border: 1px solid #eeeeee !important;
          color: #494949 !important;
          display: block;
          font-size: 0 !important;
          padding: 12px;
          text-align: center;
          transition: color 0.25s ease-in-out,
            background-color 0.25s ease-in-out;

          @media (max-width: var(--md)) {
            padding: 11px;
          }

          &:hover {
            color: var(--onPrimary) !important;

            &[aria-label='reddit'] {
              background-color: var(--reddit) !important;
            }

            &[aria-label='twitter'] {
              background-color: var(--twitter) !important;
            }

            &[aria-label='linkedin'] {
              background-color: var(--linkedin) !important;
            }

            &[aria-label='facebook'] {
              background-color: var(--facebook) !important;
            }

            &[aria-label='bluesky'] {
              background-color: var(--bluesky) !important;
            }

            svg {
              color: var(--onPrimary);
            }
          }

          + a {
            border-top: none !important;

            @media (max-width: var(--md)) {
              border-top: 1px solid #eeeeee !important;
              border-left: none !important;
            }
          }
        }
      }
    }
    &-pdf {
      padding: 12px;
    }

  }
  .article-page-title {
    grid-area: title;

    h1 {
      font-size: 48px;
      line-height: 50px;
      @media (max-width: 599px) {
        font-size: 32px;
        line-height: 32px;
      }
    }
  }
  .article-page-hero-img {
    grid-area: heroImg;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 768px ) {
      justify-content: center;
    }
    img {
      max-width: 640px;
      width: 100%;
      height: auto;
    }
  }
  .article-page-description {
    grid-area: description;


    p {
      font-family: "Roboto";
      color: #494949;
      font-size: 18px;
      margin: 10px 0;
    }
  }
  .article-page-author {
    grid-area: author;
    display: flex;
    align-items: center;
    @media (min-width: 769px) {
      margin-top: auto;
    }


    img {
      max-width: 40px;
      max-height: 40px;
      border: 2px solid #fff;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 20px;
      margin-right: 5px;



      @media (max-width: 599px) {
        display: none;
        margin-right: 0;
      }
    }
    .article-author {
      cursor: pointer;
      font-size: 12px;
      margin-bottom: unset;
    }
    .article-datetime {
      font-size: 12px;
      margin-bottom: unset;
    }


  }
  .article-page-bio {
    grid-area: bio;
    margin-top: 5px;
    font-family: "Roboto";
    font-size: 14px;

    .view-more-posts {
      color: var(--primary);
      font-weight: 700;
    }


  }

  .article-page-content {
    grid-area: content;
    position: relative;
    margin-top: 10px;
    padding-top: 40px;

    margin-bottom: 100px;

    @media (max-width: var(--md)) {
      margin-top: 16px;
    }

    > *:first-child {
      margin-top: 0px;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 500;
      letter-spacing: -0.03em;
      line-height: 1;
      margin-bottom: 16px;
      margin-top: 28px;
    }

    h2 {
      color: #000000;
      font-size: 30px;
      letter-spacing: -0.03em;
      margin-top: 90px;
      margin-bottom: 0;
    }

    h3 {
      font-size: 24px;
      margin-top: 60px;
    }

    h4 {
      font-size: 24px;
    }

    h5 {
      font-size: 22px;
    }

    h6 {
      font-size: 20px;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      margin: 16px 0;
      text-align: justify;
    }

    a {
      color: var(--primary);
    }

    .tag {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      color: #000;
      display: inline-flex;
      font-size: 14px;
      line-height: 1;
      padding: 4px 8px;
    }

    .tag-rdm {
      background-color: rgba(0, 104, 195, 0.1);
      border-radius: 8px;
      display: inline-flex;
      font-size: 14px;
      line-height: 1;
      padding: 4px 8px;
    }

    .tag-dvls {
      background-color: rgba(255, 17, 49, 0.1);
      border-radius: 8px;
      display: inline-flex;
      font-size: 14px;
      line-height: 1;
      padding: 4px 8px;
    }

    .tag-hub {
      background-color: rgba(255, 119, 23, 0.1);
      border-radius: 8px;
      display: inline-flex;
      font-size: 14px;
      line-height: 1;
      padding: 4px 8px;
    }

    .tag-pam {
      background-color: rgba(88, 166, 0, 0.1);
      border-radius: 8px;
      display: inline-flex;
      font-size: 14px;
      line-height: 1;
      padding: 4px 8px;
    }

    .tag-gateway {
      background-color: rgba(95, 3, 162, 0.1);
      border-radius: 8px;
      display: inline-flex;
      font-size: 14px;
      line-height: 1;
      padding: 4px 8px;
    }

    .tag-workspace {
      background-color: rgba(0, 0, 0, 0.03);
      border-radius: 8px;
      display: inline-flex;
      font-size: 14px;
      line-height: 1;
      padding: 4px 8px;
    }

    @media (min-width: 600px) {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #E0E0E0;
        grid-column: 1 / 3;
        margin-bottom: 10px;
      }
    }
    @media (max-width: 599px) {
      margin-top: 10px;
      padding-top: 10px;
    }

    .tabs {
      display: flex;
      width: 100%;
      align-items: flex-start;
      margin: 16px 0;

      @media (max-width: var(--md)) {
        display: block;
      }
    }

    .tabs-titles {
      display: flex;
      flex-direction: column;
      max-width: 270px;
      min-width: 180px;

      @media (max-width: var(--md)) {
        display: none;
      }

      > * {
        flex: 1;
        outline: none;
        background-color: var(--tabTitlesBackground);
        padding: 14px 20px;
        border: 1px solid white;
        border-right: 0;
        border-radius: 5px 0 0 5px;
        color: var(--primary);
        text-align: left;
        transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
        cursor: pointer;

        &:first-child {
          border-top: 1px solid #f0f0f0;
        }

        &.active {
          background-color: #f8f8f8;
        }

        &:hover {
          background: transparent;
          text-decoration: underline;
          border-color: transparent;
        }
      }

      button {
        flex: 1;
        outline: none;
        background-color: var(--tabTitlesBackground);
        padding: 14px 20px;
        border: 1px solid white;
        border-right: 0;
        border-radius: 5px 0 0 5px;
        color: var(--primary);
        cursor: pointer;
        text-align: left;
        transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
        width: 100%;

        &:first-child {
          border-top: 1px solid #f0f0f0;
        }

        &.active {
          background-color: #f8f8f8;
        }

        &:hover {
          background: transparent;
          text-decoration: underline;
          border-color: transparent;
        }
      }
    }

    .tabs-content {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      width: 100%;
      flex: 1;

      > div {
        &.active {
          height: 100%;
        }

        .tab-title {
          display: none;

          @media (max-width: var(--md)) {
            display: flex;
          }
        }

        .tab-contents {
          flex: 1;
          height: 100%;
          border: 1px solid #f0f0f0;
          background-color: #f8f8f8;
          border-top-left-radius: 0;
          align-self: stretch;
          padding: 0 20px;
          display: none;

          &.active {
            display: flex;
          }
        }
      }
    }

    blockquote {
      display: flex;
      flex-direction: column;
      margin-bottom: 60px; //temporaire et /2 quand les boutons des 3 articles du haut seront là
      padding: 30px 47px;
      width: 100%;
      background: rgba($color: #0368c3, $alpha: 0.05);
      border-radius: 10px;

      > p > strong {
        color: var(--onSecondary);
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.84px;
        font-family: var(--default);
        text-align: left;
        font-weight: bold;
        font-style: normal;
        text-transform: capitalize;
      }

      p {
        color: var(--onSecondary);
        font-style: italic;
        font-size: 18px;
        line-height: 30px;
        text-align: left;
        letter-spacing: -0.9px;
        align-items: center;
      }

      > *:first-child {
        margin-top: 0;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }

    code {
      background-color: #E4E5E6;
      border-radius: 5px;
      color: #000000;
      font-family: 'IBM Plex Mono', monospace;
      font-size: 14px;
      line-height: 24px;
      padding: 2px 6px;
    }

    pre {
      background-color: #2e3440;
      border-radius: 10px;
      color: #f8f8f2;
      display: block;
      font-family: 'IBM Plex Mono', monospace;
      font-size: 14px;
      line-height: 24px;
      margin: 16px auto;
      overflow-x: auto;
      padding: 16px 24px;

      code {
        background-color: #2e3440;
        color: #f8f8f2;
        padding: 0;
      }
    }

    ul,
    ol {
      padding-left: 40px;
    }

    li {
      font-size: 18px;
      line-height: 24px;

      &::marker {
        color: var(--team);
      }

      + li {
        margin-top: 16px;
      }
    }

    figure {
      text-align: center;
    }

    img {
      display: inline-block;
      vertical-align: middle;
    }

    table {
      border-collapse: collapse;
      border-radius: 10px;
      box-shadow: 0 8px 10px rgb(0 0 0 / 24%);
      display: inline-block;
      font-size: 14px;
      max-width: 100%;
      overflow-x: auto;

      th {
        background-color: var(--tablesBackground);

        &:first-child {
          border-top-left-radius: 10px;
        }

        &:last-child {
          border-top-right-radius: 10px;
        }
      }

      th,
      td {
        padding: 16px;
        text-align: left;

        &:first-child {
          padding-left: 40px;
        }

        &:last-child {
          padding-right: 40px;
        }
      }

      tr {
        &:hover {
          td {
            background-color: #f5f5f5;
          }
        }

        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 10px;
            }

            &:last-child {
              border-bottom-right-radius: 10px;
            }
          }
        }

        + tr {
          td {
            border-top: 1px solid var(--tablesBackground);
          }
        }
      }
    }

    h2 {
      font-style: normal;
    }
  }

  .article-page-toc {
    position: relative;
    grid-area: toc;
    margin-top: 10px;
    padding-top: 40px;
    @media (min-width: 769px) {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -20px;
        right: 0;
        height: 1px;
        background-color: #E0E0E0;
        margin-bottom: 10px;
      }
    }

    display: flex;
    justify-content: flex-end;


    @media (max-width: 599px) {
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-top: 0;
    }

    @media (min-width: 600px) and (max-width: 768px) {
      justify-content: center;
      margin-top: 0;
      padding-top: 0;


    }


    .dwd-toc-container {
      background-color: #F5F5F5;
      height: fit-content;
      max-width: 280px;
      padding: 16px;
      position: sticky;
      top: 100px;
      width: 280px;

      @media (min-width: 600px){
        margin-left: 30px;
      }

      @media (min-width: 600px) and (max-width: 768px) {
        position: relative;
        max-width: 640px;
        width: 100%;
        top: unset;
        margin-left: 0;
        margin-bottom: 20px;
        padding-left: 100px;
        padding-right: 100px;
      }

      @media (max-width: 599px) {
        max-width: unset;
        width: 100%;
      }

      .dwd-toc {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        gap: 14px;
        overflow: hidden;

        .toc-body a.active {
          // text-decoration: underline;
          color: #007bff;
        }

        .toc-header {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (min-width: 769px) {
            pointer-events: none;
          }
          span {
            font-size: 30px;
            letter-spacing: -0.03em;
            font-weight: 700;
          }

          svg {
            transform: rotate(0deg);
            transition: transform 0.5s ease-in-out;
            @media (min-width: 769px) {
              display: none;
            }
          }
          &.active {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        .toc-body {
          transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
          @media (max-width: 768px) {
            max-height: 0;
            opacity: 0;
          }

          &.active {
            opacity: 1;
          }
        }

        ul {
          transition: margin 0.5s ease-in-out ;
          list-style-type: none;
          padding-left: 0;
          width: fit-content;
          margin: 0;

          & > li {
            border-bottom: 1px solid #E0E0E0;
            padding-bottom: 14px;
            margin-bottom: 14px;

            & > a {
              color: #000;

              &:hover {
                color: var(--primary);
              }
            }

            &::marker {
              color: #000;
              content: "• ";
            }

            &:last-child {
              border-bottom: none;
              margin-bottom: 0;
              padding-bottom: 0;
            }
          }


          & > li > ul {
            list-style-type: disc;
            list-style-position: inside;
            padding-top: 0;
            margin: 14px 0 0 0;

            & > li {
              padding: 0 0 0 8px;
              border-bottom: none;
            }
          }
        }

      }
    }
  }
  .article-page-bottom {
    grid-area: bottom;
    margin-bottom: 100px;
  }

  @media (max-width: 768px) {
    grid-gap: 10px 20px;

    grid-template-columns: 1fr 1fr;

    grid-template-areas: "category category "
                         "title title "
                         "description description  "
                         "author links "
                         "bio bio "
                         "toc toc "
                         "heroImg heroImg "
                         "content content "
                         "bottom bottom";

  }

  @media (max-width: 599px) {
    grid-gap: 10px 20px;

    grid-template-columns: 3fr 1fr;

    grid-template-areas: "category category "
                         "title title "
                         "description description  "
                         "author links "
                         "bio bio "
                         "toc toc "
                         "heroImg heroImg "
                         "content content "
                         "bottom bottom ";
  }

}
