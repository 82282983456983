/* Arrow */

.arrow::after {
  display: inline-block;
  font-size: 26px;
  font-weight: 600;
  width: 30px;
  text-align: center;
  margin-left: 15px;
}

.right::after {
  content: '\2192';
  align-items: center;
}

@keyframes offset {
  from {
    transform: translateX(-5px);
  }
  to {
    transform: translateX(10px);
  }
}

.arrow.offset:hover::after {
  transform: translateX(5px);
  transition: all 0.5s;
  animation-duration: 0.5s;
  animation-direction: alternate;
}
