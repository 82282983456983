@use '../variables' as *;

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $space in $spacing {
    .#{$abbrev}-#{$space} {
      #{$prop}: #{$space}px !important;
    }
    .#{$abbrev}t-#{$space} {
      #{$prop}-top: #{$space}px !important;
    }
    .#{$abbrev}r-#{$space} {
      #{$prop}-right: #{$space}px !important;
    }
    .#{$abbrev}b-#{$space} {
      #{$prop}-bottom: #{$space}px !important;
    }
    .#{$abbrev}l-#{$space} {
      #{$prop}-left: #{$space}px !important;
    }
    .#{$abbrev}x-#{$space} {
      #{$prop}-right: #{$space}px !important;
      #{$prop}-left: #{$space}px !important;
    }
    .#{$abbrev}y-#{$space} {
      #{$prop}-top: #{$space}px !important;
      #{$prop}-bottom: #{$space}px !important;
    }
  }
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}